import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  RouterStateSnapshot
} from '@angular/router';
import { CurrentUserStore } from '@pinnakl/core/data-providers';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

export const AuthenticatedGuardCanActivate: CanActivateFn = () => {
  const authService = inject(AuthService);
  const currentUserStore = inject(CurrentUserStore);

  return authService.getIsAuthenticated().pipe(
    take(1),
    switchMap(isAuthenticated => {
      if (isAuthenticated) {
        const currentUserInfoLoaded = currentUserStore.currentUserInfoLoaded();
        const currentUserInfoLoading = currentUserStore.currentUserInfoLoading();

        if (
          (!currentUserInfoLoaded && !currentUserInfoLoading) ||
          !currentUserStore.currentUserInfo()
        ) {
          authService.silentlyFetchUser();
        }

        return of(true);
      }

      const { search } = new URL(window.location.href);
      const searchParams = new URLSearchParams(search);
      const hasCode = searchParams.has('code');
      if (hasCode) {
        authService.checkAuthCodeAndProceedAuth();
        return of(false);
      }
      currentUserStore.removeUser();
      authService.redirectToAuthApp();
      return of(false);
    })
  );
};

export const AuthenticatedGuardCanActivateChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => AuthenticatedGuardCanActivate(route, state);
