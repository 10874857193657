import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { ISecurity, SecurityBase, SecurityPostPayload } from '@pinnakl/securities/domain';
import { V3_ENDPOINT, V4_ENDPOINT } from '@pinnakl/shared/constants';
import { SecurityFromApi, SecuritySearchResult } from '@pinnakl/shared/types';
import moment from 'moment';
import { Observable, map } from 'rxjs';

@Injectable()
export class SecuritiesApiService {
  private readonly securitiesEndpointLegacy = `entities/securities`;
  private readonly securitiesEndpoint = `${V4_ENDPOINT}securities`;
  private readonly securitiesSearchEndpoint = `${V3_ENDPOINT}security_search`;

  private readonly securityFields = [
    'Id',
    'AssetType',
    'AssetTypeId',
    'SecType',
    'secTypeId',
    'SecTypeDescription',
    'Ticker',
    'Cusip',
    'Sedol',
    'LoanId',
    'OpraCode',
    'Isin',
    'Description',
    'PrivateIndicator',
    'CurrencyId',
    'Currency',
    'Multiplier',
    'Principal_Factor',
    'InitialMargin',
    'MaintenanceMargin',
    'Maturity',
    'underlyingsecid',
    'active_trading_indicator',
    'bloombergidentifier',
    'clientid',
    'country_of_quotation',
    'countryofincorporation',
    'countryofrisk',
    'datasource',
    'manualpricingindicator',
    'marketid',
    'mic',
    'moodyrating',
    'organization_id',
    'organization_status_descr',
    'organization_status_id',
    'organizationname',
    'organizationticker',
    'pricingsourcename',
    'primary_market_indicator',
    'sandprating',
    'sector',
    'securitymarketid',
    'securitypricingid',
    'sharesoutstanding',
    'userid',
    'countryOfQuotationId',
    'countryOfQuotationCode',
    'countryOfQuotationName',
    'countryOfIncorporationCode',
    'countryOfIncorporationName'
  ];

  constructor(private readonly wsp: WebServiceProvider) {}

  getSecurities(securityMarketId?: string): Observable<ISecurity[]> {
    const params: Record<string, any> = {
      fields: this.securityFields
    };
    if (securityMarketId) {
      params['filters'] = [
        {
          key: 'securityMarketId',
          type: 'EQ',
          value: [securityMarketId]
        }
      ];
    }
    return this.wsp
      .get<SecurityFromApi[]>({
        endpoint: this.securitiesEndpointLegacy,
        optionsParams: params
      })
      .pipe(
        map(securities =>
          securities.map(entity => {
            const assetTypeId = +entity.assettypeid,
              currencyId = +entity.currencyid,
              dataSourceId = +entity.datasource,
              id = +entity.id,
              multiplier = +entity.multiplier,
              organizationId = +entity.organization_id,
              securityTypeId = +entity.sectypeid,
              principalFactor = parseFloat(entity.principal_factor),
              initialMargin = parseFloat(entity.initialmargin),
              maintenanceMargin = parseFloat(entity.maintenancemargin),
              maturityMoment = moment(entity.maturity, 'MM/DD/YYYY');

            return {
              assetType: entity.assettype,
              assetTypeId: assetTypeId,
              countryOfIncorporation: entity.countryofincorporation,
              countryOfRisk: entity.countryofrisk,
              countryOfQuotationId: +entity.countryofquotationid,
              countryOfQuotationCode: entity.countryofquotationcode,
              countryOfQuotationName: entity.countryofquotationname,
              countryOfIncorporationCode: entity.countryofincorporationcode,
              countryOfIncorporationName: entity.countryofincorporationname,
              currency: entity.currency,
              currencyId: currencyId,
              cusip: entity.cusip,
              dataSourceId: dataSourceId,
              description: entity.description,
              id: id,
              isin: entity.isin,
              loanId: entity.loanid,
              manualPricingIndicator: entity.manualpricingindicator === 'True',
              moodyRating: entity.moodyrating,
              multiplier: multiplier,
              opraCode: entity.opracode,
              organizationId: organizationId,
              organizationName: entity.organizationname,
              organizationStatusDescription: entity.organization_status_descr,
              organizationStatusId: entity.organization_status_id,
              organizationTicker: entity.organizationticker,
              privateIndicator: entity.privateindicator === 'True',
              sandpRating: entity.sandprating,
              sector: entity.sector,
              securityType: entity.sectype,
              securityTypeDescription: entity.sectypedescription,
              securityTypeId: securityTypeId,
              sedol: entity.sedol,
              ticker: entity.ticker,
              principalFactor: principalFactor,
              initialMargin: initialMargin,
              maintenanceMargin: maintenanceMargin,
              maturity: maturityMoment.toDate(),
              underlyingsecid: entity.underlyingsecid ? +entity.underlyingsecid : null,
              sharesOutstanding: +entity.sharesoutstanding,
              pricingSourceName: entity.pricingsourcename,
              securityPricingId: entity.securitypricingid,
              securityMarketId: +entity.securitymarketid,
              marketId: +entity.marketid,
              securityId: +entity.id
            };
          })
        )
      );
  }

  getSecurityInfoBySecurityMarketId(id: string): Observable<ISecurity> {
    return this.getSecurities(id).pipe(map(values => values[0]));
  }

  searchSecurities(
    text: string,
    assetType: string | null,
    additionalFilters: {
      key: string;
      type: string;
      value: string[];
    }[] = [],
    includeCheckForExpiration = false
  ): Observable<SecuritySearchResult[]> {
    const filters = [{ key: 'searchstring', type: 'LIKE', value: [text] }].concat(
      additionalFilters
    );
    if (assetType) {
      filters.push({ key: 'assettype', type: 'EQ', value: [assetType] });
      if (includeCheckForExpiration) {
        filters.push({
          key: 'checkforexpiration',
          type: 'EQ',
          value: [`${['option', 'future'].includes(assetType).toString()}`]
        });
      }
    }
    return this.wsp.get<SecuritySearchResult[]>({
      endpoint: this.securitiesSearchEndpoint,
      optionsParams: {
        filters
      }
    });
  }

  postSecurity(payload: SecurityPostPayload): Observable<SecurityBase> {
    return this.wsp.post<SecurityBase>({
      endpoint: this.securitiesEndpoint,
      body: payload
    });
  }

  getSecurityById(id: number): Observable<SecurityBase> {
    return this.wsp.get<SecurityBase>({
      endpoint: `${this.securitiesEndpoint}/${id}`
    });
  }
}
