import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { EnvironmentService } from '@pinnakl/core/environment';
import { withDevtools } from '@pinnakl/shared/signal-store';
import { CurrentUserInfoParsed, OtpChannelType, UserAuthType } from '@pinnakl/shared/types';
import { getAppInstanceToken } from '@pinnakl/shared/util-helpers';
import { CurrentUserApiService } from './current-user-api.service';

export type CurrentUserUpdatePayload = Partial<{
  firstName: string;
  lastName: string;
  phone: string;
  timeZone: string;
  password: string;
  authType: UserAuthType;
  otpChannel: OtpChannelType | null;
  tokenReauthInterval: number | null;
}>;

interface CurrentUserStoreState {
  userTokenStorageKey: string;
  currentUserInfoInStore: CurrentUserInfoParsed | null;
  currentUserInfoLoading: boolean;
  currentUserInfoLoaded: boolean;
  currentUserUpdateProcessing: boolean;
}

const getInitialState = () => ({
  currentUserInfoInStore: null,
  currentUserInfoLoading: false,
  currentUserInfoLoaded: false,
  currentUserUpdateProcessing: false
});

export const CurrentUserStore = signalStore(
  { providedIn: 'root' },
  withDevtools('currentUser'),
  withState<CurrentUserStoreState>((environmentService = inject(EnvironmentService)) => ({
    ...getInitialState(),
    userTokenStorageKey: `user-${getAppInstanceToken(environmentService.get('authConfig')?.configId, location?.host, environmentService.get('appName'))}`
  })),
  withComputed(store => ({
    currentUserInfo: computed((): CurrentUserInfoParsed | null => {
      const userInfoInStore = store.currentUserInfoInStore();
      if (userInfoInStore) {
        return userInfoInStore;
      }
      try {
        const userTokenStorageKey = store.userTokenStorageKey();
        const userInfoFromStorageStr = localStorage.getItem(userTokenStorageKey);
        if (!userInfoFromStorageStr) {
          return null;
        }

        return JSON.parse(userInfoFromStorageStr) as CurrentUserInfoParsed;
      } catch (e) {
        console.error('Issue while parsing user info from storage', e);
        return null;
      }
    })
  })),
  withComputed(store => ({
    currentUser: computed(() => store.currentUserInfo()?.user ?? null)
  })),
  withMethods((store, api = inject(CurrentUserApiService)) => ({
    getCurrentUserInfo: async (
      forceFetch = false,
      accessToken?: string
    ): Promise<CurrentUserInfoParsed> => {
      patchState(store, { currentUserInfoLoading: true });
      try {
        const storedCurrentUserInfo = store.currentUserInfo();
        if (storedCurrentUserInfo && !forceFetch) {
          return storedCurrentUserInfo;
        }
        const userInfo = await api.getCurrentUserInfo(accessToken);
        patchState(store, { currentUserInfoInStore: userInfo, currentUserInfoLoaded: true });
        const userTokenStorageKey = store.userTokenStorageKey();
        localStorage.setItem(userTokenStorageKey, JSON.stringify(userInfo));
        return userInfo;
      } catch (e) {
        patchState(store, { currentUserInfoLoaded: false });
        throw e;
      } finally {
        patchState(store, { currentUserInfoLoading: false });
      }
    },
    updateUser: async (
      userUpdateData: CurrentUserUpdatePayload
    ): Promise<CurrentUserInfoParsed | null> => {
      patchState(store, { currentUserUpdateProcessing: true });
      try {
        const storedCurrentUserInfo = store.currentUserInfo();
        if (!storedCurrentUserInfo) return null;
        const user = await api.updateUserInfo(storedCurrentUserInfo.user.id, userUpdateData);
        const userInfo = { ...storedCurrentUserInfo, user };
        patchState(store, { currentUserInfoInStore: userInfo });
        const userTokenStorageKey = store.userTokenStorageKey();
        localStorage.setItem(userTokenStorageKey, JSON.stringify(userInfo));
        return userInfo;
      } finally {
        patchState(store, { currentUserUpdateProcessing: false });
      }
    },
    removeUser: (): void => {
      const userTokenStorageKey = store.userTokenStorageKey();
      localStorage.removeItem(userTokenStorageKey);
      patchState(store, getInitialState());
    }
  }))
);
